import { mapActions, mapGetters, mapMutations } from "vuex";
import navigateTo from "../../mixins/buy-flow";
import { debounce } from "lodash";
import { directive as onClickaway } from "vue-clickaway";
import burgerNav from "./components/burger-nav/index.vue";
import adminBar from "@/components/admin-utils/admin-bar/index.vue";
import { isClickableInMainMenu, isProcessableItem, routeForMenuItem, routeToProduct } from "@/helpers";

export default {
    name: "main-header",
    mixins: [navigateTo],
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        adminBar,
        burgerNav,
    },

    data() {
        return {
            querySearch: "",
            show: false,
            openBurger: false,
            showSearch: false,
            windowWidth: window.innerWidth <= 980,
            headerMessages: [],
            searchLoading: false,
            searchResultData: { news: [], products: [] },
        };
    },
    watch: {
        $route() {
            this.openBurger = false;
        },
        openBurger() {
            document.getElementById("html").classList[this.openBurger ? 'add' : 'remove' ]("hide");
            document.querySelector("#app").classList[this.openBurger ? 'add' : 'remove' ]("hide");
            document.querySelector("body").classList[this.openBurger ? 'add' : 'remove' ]("hide");
        },
        isProfessional: function() {
            this.fetchHeaderMenu();
        },
    },
    created() {
        this.fetchHeaderMenu();
        // window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.getHeaderMessages().then(headerMessages => {
            this.headerMessages = headerMessages;
            window.setInterval(() => {
                this.pollHeaders();
            }, 5000);
        });
    },
    computed: {
        ...mapGetters(["languages", "currentLanguage"]),
        ...mapGetters({
            isAuthenticated: "auth/isAuthenticated",
            basket: "basket/basket",
            user: "profile/user",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            isUserWithCode: "profile/isUserWithCode",
            headerMenu: "setting/headerMenu",
            whichList: "favorites/whichList",
            contacts: "setting/variables",
        }),
    },
    methods: {
        isClickableInMainMenu: isClickableInMainMenu,
        routeToProduct: routeToProduct,
        ...mapMutations({
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeRegisterPopup: "popups/CHANGE_REGISTRATION_POPUP",
            changeLangModal: "system/SET_SHOW_LANG_POPUP",
            changeCountryModal: "popups/CHANGE_COUNTRY_MODAL"
        }),
        ...mapActions({
            fetchSearchItems: "search/GET_SEARCH_LIST",
            fetchHeaderMenu: "setting/GET_HEADER_MENU",
            getHeaderMessages: "home/GET_HEADER_MESSAGES",
        }),
        async fetchResults() {
            this.searchLoading = true;
            this.searchResultData = await this.fetchSearchItems(this.querySearch);
            this.searchLoading = false;
        },
        sendRequest: debounce(function() {
            if (this.querySearch.length > 2) {
                this.fetchResults();
            }
        }, 500),
        isProcessableItem: isProcessableItem,
        changeSavedLanguage(data) {
          localStorage.setItem('app_locale', data.key)
        },
        pollHeaders() {
            const first = this.headerMessages.shift();
            this.headerMessages = this.headerMessages.concat(first);
        },
        /**
         * @param {NavigationItem} navItem
         * @param {NavigationItem|null} parentNavItem
         * @return {Object}
         */
        routeForMenuItem(navItem, parentNavItem = null) {
            return this.$localize(routeForMenuItem(navItem, parentNavItem));
        },
        redirectToSearchPage() {
            this.setFocusField(false);
            // setTimeout(() => {
            this.$router.push({ name: "result", query: { search: this.querySearch } });
            // }, 500)
        },
        myEventHandler() {
            if (window.innerWidth <= 980) {
                this.showSearch = !this.showSearch;
            }
        },

        showPopup() {
            if (!this.isAuthenticated) {
                this.show = false;
                this.openBurger = false;
                this.changeLoginPopup(true);
            } else {
                this.navigateTo("profile");
            }
        },
        showLoginPopup() {
            this.show = false;
            this.openBurger = false;
            this.changeLoginPopup(true);
        },
        showRegisterPopup() {
            this.show = false;
            this.openBurger = false;
            this.changeRegisterPopup(true);
        },
        setFocusField(status) {
            this.show = status;
        },
        changeSearch() {
            this.sendRequest();
        },
        changeLanguage(code) {
            this.$setLanguage(code).then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 900);
            });
        },
        generateLanguageLink(code) {
            const lang = this.languages.find(({ key }) => key === code);
            if (lang.urlPrefix.length) {
                const link = this.$router.resolve({ ...this.$route, params: { ...this.$route.params, lang: code } });
                return link.href;
            }
            const link = this.$router.resolve({ ...this.$route, params: { ...this.$route.params, lang: null } });
            return link.href;
        },
        away: function() {
            this.querySearch = "";
            this.showSearch = false;
        },
        openCountryModal() {
            this.changeCountryModal({ status: true });
            this.openBurger = false;
        },
        /**
         * @param {keyof GeneralContacts|string} field
         * @return {string}
         */
        getContact(field) {
            return this.contacts?.contacts[field] || "";
        },
        openLangPopup() {
            this.changeLangModal(true);
        }
    },

    destroyed() {
        // window.removeEventListener("resize", this.myEventHandler);
    },
};
