import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import { $http } from "@/utils/https";

interface State {
    regionsList: any;
    regionsListLoaded: boolean;
    currentRegion: any;
}

const state: State = {
    regionsList: null,
    regionsListLoaded: false,
    currentRegion: null,
}

const getters = {
    regionsList: (state: State) => state.regionsList,
    regionsListLoaded: (state: State) => state.regionsListLoaded,
    currentRegion: (state: State) => state.currentRegion,
    currentRegionCurrency: (state: State) => state.currentRegion?.currency,
}

const actions = {
    GET_REGIONS_LIST: async ({commit}: ActionContext<State, RootState>) => {
        commit('SET_REGIONS_LIST', []);
        commit('SET_REGIONS_LIST_LOADED', false);
        try {
            const response = await $http.get(`v1/regions/list`);
            const transformedData = response.data.map((item: any) => {
                if(item.name === 'Czechia') {
                    return {
                        ...item,
                        name: 'Czech Republic'
                    }
                }
                return item;
            });
            commit('SET_REGIONS_LIST', transformedData);
            commit('SET_REGIONS_LIST_LOADED', true);
            return transformedData;
        } catch (e) {
            throw e;
        } finally {}
    },
    FETCH_CURRENT_REGION: async ({commit}: ActionContext<State, RootState>) => {
        const response = await $http.get(`v1/regions/current`);
        commit('SET_CURRENT_REGION', response.data);
        return response.data;
    },
}

const mutations = {
    SET_REGIONS_LIST(state: State, value: any) {
        state.regionsList = value;
    },
    SET_REGIONS_LIST_LOADED(state: State, value: boolean) {
        state.regionsListLoaded = value;
    },
    SET_CURRENT_REGION(state: State, value: any) {
        state.currentRegion = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
