
import { mapActions, mapGetters, mapMutations } from "vuex";
import Hidden from "@/components/hidden/index.vue";
import MainHeader from "@/components/header/index.vue";
import MainFooter from "@/components/footer/index.vue";
import { isIPad, isMobile } from "@/helpers/variables";

import { defineComponent } from "vue";

export default defineComponent({
    name: "app",
    data() {
        return {
            isShowSocials: false,
        };
    },
    components: {
        Hidden,
        MainHeader,
        MainFooter,
    },
    computed: {
        ...mapGetters({
            isAuthenticated: `auth/isAuthenticated`,
            variables: `setting/variables`,
            globalLoader: "system/globalLoader",
        }),
        // isFooterInViewport() {
        //     const pageHeight = document.documentElement.clientHeight;
        //     const footer = document.querySelector(".footer");
        //     const footerTop = footer.getBoundingClientRect().top;
        //     return footerTop < pageHeight;
        // }
    },
    created() {
        if (this.isAuthenticated) {
            this.fetchUser();
            this.getFavorites();
        }
        this.getVariables();
        this.getPages();
        this.checkSelectedCountry();
    },
    mounted() {
        if (!isMobile.any()) {
            document.querySelector("body")?.classList.add("hover");
        }
        if (!isIPad.iOS()) {
            document.querySelector("body")?.classList.add("iPad");
        }
    },
    methods: {
        ...mapMutations({
            changeFeedbackModal: "popups/CHANGE_FEEDBACK_MODAL",
            changeCountryModal: "popups/CHANGE_COUNTRY_MODAL",
        }),
        ...mapActions({
            fetchUser: "profile/FETCH_USER_DATA",
            getFavorites: "favorites/GET_FAVORITES_LIST",
            getVariables: "setting/GET_VARIABLES",
            getPages: "pages/FETCH_PAGES",
            fetchCurrentRegion: "regions/FETCH_CURRENT_REGION",
        }),
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        showSocials() {
            this.isShowSocials = !this.isShowSocials;
        },
        openFeedbackModal() {
            this.changeFeedbackModal({ status: true });
        },
        openCountryModal() {
            this.changeCountryModal({ status: true });
        },
        checkSelectedCountry() {
            const selectedCountry = localStorage.getItem("country");
            setTimeout(() => {
                if (!selectedCountry) {
                    this.openCountryModal()
                } else {
                    this.fetchCurrentRegion();
                }
            }, 1000);
        }
    },
});
